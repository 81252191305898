import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react'
import { Imprimir, Filtro, BtnAcao } from './Acoes'
import ReactToPrint from 'react-to-print'
import { formatarMoeda, meses } from '../util/Utilitarios'
import { BrowserRouter, withRouter, Link } from 'react-router-dom'
import '../assets/css/relatorio.css'
import { getUsuario } from '../util/Configuracoes'
import { BotaoPadrao } from './Botoes'
import { ModalCadastro } from './Modais'
import { ordenar, ordenarMultiplo } from './Ordenamentos'
import { InputSwitchSemFormik } from './Inputs'
import { LoadRelatorio } from './Loading'
import moment from 'moment'
import axios from 'axios'
import { mostrarToast, toastPrintMessage } from './Toasts'
import { isMobile } from "react-device-detect";
import CsvBuilder from './CsvBuilder'
import { TableVirtuoso } from 'react-virtuoso'
import HTMLToPDF from './PdfBuilder'
import ReactDOMServer from 'react-dom/server';
import { comprimirString, removeContentBetweenDivs, stringSizeMB } from '../util/FuncoesComuns'
import MensagemPadraoRelatorio from '../components/RelatorioEspecifico/RelatorioSemDados'
import { toast } from 'react-toastify'

// COMO USAR

// titulo = titulo do relatorio
// filtros = {
//         filtro: 'nome para exibir',
//         dado: 'dado da filtragem'
//     },
//  dados = [{
//      agrupamento: 'nome para exibir',
//      numerarLinha: true or false. Default false
//      colunas: [{
//          label: 'nome para exibir',
//          name: 'coluna correspondente em data',
//          totalizar: true or false,
//          alinhamento: 'text-center || text-right',
//          visivel: true or false,
//          ordenamento: {
//              tipo: 'tipo do metodo de ordenamento',
//              ativo: true or false, define se virá ordenado ou não
//              modo: 'desc' or 'asc'
//          }
//      }]
//  }]
// showModalFiltros,
// setShowModalFiltros
// campos de filtros dentro do componente

const ConsultaRelatorio = React.forwardRef((props, ref) => {
    const relRef = useRef(null)
    const user = getUsuario();
    const reference = useRef();
    const btnPrint = useRef();
    const actualDate = new Date();
    const [arrayConfigCol, setArrayConfigCol] = useState([])
    const [arrayOrdenamento, setArrayOrdenamento] = useState([])
    const [showModalColunas, setShowModalColunas] = useState(false)
    const [loadingPrint, setLoadingPrint] = useState(false)
    const [arrayFiltro, setArrayFiltro] = useState([])

    const obterOrdenamentosMultiplos = idx => {
        if (arrayOrdenamento[idx] && arrayOrdenamento[idx].filter(item => item === true).length > 0) {
            let ordenamentos = arrayConfigCol[idx].filter((item, idi) => arrayOrdenamento[idx][idi]).sort((a, b) => { return a.ordenamento.ordem - b.ordenamento.ordem })
            return ordenamentos
        }
        return []
    }

    if (ref)
        ref.current = { arrayConfigCol, arrayOrdenamento, obterOrdenamentosMultiplos }


    useEffect(() => {
        const dadosNum = props.dados.filter(item => item.numerarLinha)
        if (dadosNum.length > 0) {
            props.dados.map((item, idxAg) => {
                if (item.numerarLinha && props.dados[idxAg]?.colunas && props.dados[idxAg].colunas[0]?.name !== '#')
                    props.dados[idxAg].colunas.unshift({ label: '#', name: '#', alinhamento: 'text-center', visivel: true, width: '50px' })
            })
        }

        if (dadosNum.length > 0) {
            props.dados.map((item, idxAg) => {
                if (idxAg)
                    props.dados[idxAg].colunas.shift({ label: '#', name: '#', alinhamento: 'text-center', visivel: true, width: '50px' })
            })
        }


        let preArrayOrdenamento = props.dados.length ? Array(props.dados.length).fill({}) : []
        preArrayOrdenamento = props.dados.map((ag, idxAg) => {
            let contador = 0;
            return ag.colunas ? ag.colunas.map((item, idxColuna) => {

                if (item.ordenamento && item.ordenamento.ativo)
                    contador++
                return {
                    coluna: item.name,
                    ordenamento: item.ordenamento && item.ordenamento.ativo ? {
                        modo: item.ordenamento.modo || 'asc',
                        tipo: item.ordenamento.tipo || 'texto',
                        ativo: true,
                        ordem: contador,
                        ordenar: item.ordenamento?.ordenar || null
                    } : {
                        modo: 'asc',
                        tipo: item.ordenamento ? item.ordenamento.tipo || 'texto' : 'texto',
                        ativo: false,
                        ordenar: item.ordenamento?.ordenar || null

                    },
                    visivel: arrayConfigCol[0] && arrayConfigCol[0][0] ? arrayConfigCol[idxAg][idxColuna].visivel : (item.visivel ? true : false)
                }
            }) : []
        })
        const mapOrdenamentos = arrayConfigCol.filter(itemOrd => preArrayOrdenamento.findIndex(x => x.coluna === itemOrd.coluna) > -1)

        if (arrayConfigCol.length === 0 || (Array.isArray(arrayConfigCol[0]) && arrayConfigCol[0].length === 0) || (preArrayOrdenamento.length !== arrayConfigCol.length && (mapOrdenamentos !== undefined && mapOrdenamentos.length !== arrayConfigCol.length)) || props.dados[0].colunas.length !== arrayConfigCol.length) {
            setArrayOrdenamento(preArrayOrdenamento.map(item => item.map(coluna => coluna.ordenamento.ordem ? true : false)))
            setArrayConfigCol(preArrayOrdenamento)
        }
        else {
            setArrayOrdenamento(arrayOrdenamento)
            setArrayConfigCol(arrayConfigCol)
        }
    }, [props.dados])

    useEffect(() => {
        if (props.filtros && props.filtros.length > 0)
            setArrayFiltro(props.filtros)
    }, [props.filtros])

    const setOrdenamento = useCallback((idxColuna, data, idxOrdenamento) => {
        let ultima = 0;
        const cloneArrayConfigCol = arrayConfigCol[idxOrdenamento]
        for (let i = 0; i < cloneArrayConfigCol.length; i++) {
            if (cloneArrayConfigCol[i].ordenamento.ordem && cloneArrayConfigCol[i].ordenamento.ordem > ultima)
                ultima = cloneArrayConfigCol[i].ordenamento.ordem
        }

        let cloneArray = [...arrayConfigCol]
        let cloneIniciarOrdenamento = [...arrayOrdenamento]
        if (cloneArray.length > 0) {
            let objOrdenamento = { ...cloneArray[idxOrdenamento][idxColuna] }

            if (!objOrdenamento.ordenamento.ativo) {
                objOrdenamento.ordenamento.ativo = true
                objOrdenamento.ordenamento.ordem = ultima + 1
            } else
                objOrdenamento.ordenamento.modo = objOrdenamento.ordenamento.modo === 'asc' ? 'desc' : 'asc'

            cloneArray[idxOrdenamento].splice(idxColuna, 1, objOrdenamento)
            cloneIniciarOrdenamento[idxOrdenamento].splice(idxColuna, 1, true)
            setArrayOrdenamento(cloneIniciarOrdenamento)
            setArrayConfigCol(cloneArray)
        }
    })

    const mudarVisibilidadeColuna = (idxAgrupamento, idxColuna, valor) => {
        let cloneArray = [...arrayConfigCol]
        let cloneAgrupamento = [...arrayConfigCol[idxAgrupamento]]
        let cloneColuna = { ...cloneAgrupamento[idxColuna] }
        cloneColuna.visivel = valor !== undefined ? valor : !cloneColuna.visivel
        cloneAgrupamento.splice(idxColuna, 1, cloneColuna)
        cloneArray.splice(idxAgrupamento, 1, cloneAgrupamento)
        setArrayConfigCol(cloneArray)
    }

    const mudarVisibilidadeColunaUnico = (idxColuna, valor) => {
        let cloneArray = [...arrayConfigCol]
        cloneArray = cloneArray.map((x, idx) => {
            x[idxColuna].visivel = valor;
            return x
        });
        setArrayConfigCol(cloneArray)
    }

    const colunasSaoIguais = (props) => {
        return props.visibilidadeColunasNaoAgrupado === undefined ? false : props.visibilidadeColunasNaoAgrupado
    }

    const obterChecksColunasIguais = (props) => {
        let x = props.dados[0];
        return (<>
            {
                x.colunas && x.colunas.map((y, idy) => {
                    return <div className="row-space-between ptb-15 plr-20 bb-1" >
                        <div>
                            <span className="titulo-acao">{y.label}</span>
                        </div>
                        <InputSwitchSemFormik
                            tamanho="-"
                            size="md"
                            checked={arrayConfigCol[0][idy] && arrayConfigCol[0][idy].visivel}
                            onClick={() => {
                                mudarVisibilidadeColunaUnico(idy, (!arrayConfigCol[0][idy].visivel));
                            }} />
                    </div>
                })
            }
        </>)
    }

    const obterChecksMudarVisibilidade = (props) => {

        if (colunasSaoIguais(props)) {
            return obterChecksColunasIguais(props)
        }

        return (<> {
            props.dados.map((x, idx) =>
                <>
                    {x.agrupamento && <h6 className="mt-2"><b>{x.agrupamento}</b></h6>}

                    {/* COLUNAS */}
                    {x.colunas && x.colunas.map((y, idy) => {
                        if (!y.desabilitarCheckColuna)
                            return <div className="row-space-between ptb-15 plr-20 bb-1" >
                                <div>
                                    <span className="titulo-acao">{y.label}</span>
                                </div>
                                <InputSwitchSemFormik
                                    tamanho="-"
                                    size="md"
                                    checked={arrayConfigCol[idx][idy] && arrayConfigCol[idx][idy].visivel}
                                    onClick={() => mudarVisibilidadeColuna(idx, idy)} />
                            </div>
                    })}</>
            )
        }</>)
    }

    const obterDataOrdenado = (idx, data) => {
        if (arrayOrdenamento[idx] && arrayOrdenamento[idx].filter(item => item === true).length > 0) {
            let ordenamentos = arrayConfigCol[idx].filter((item, idi) => arrayOrdenamento[idx][idi]).sort((a, b) => { return a.ordenamento.ordem - b.ordenamento.ordem })
            return ordenarMultiplo(data, ordenamentos)
        }
        return data
    }

    const limparOrdenamentos = (idx) => {
        let cloneArray = []
        cloneArray = Array(arrayOrdenamento[idx].length).fill(false)
        let cloneArrayConfig = arrayConfigCol[idx]
        cloneArrayConfig = cloneArrayConfig.map(item => {
            item.ordenamento.ativo = false
            item.ordenamento.modo = 'asc'
            delete item.ordenamento.ordem
            return item
        })
        var cloneArrayOrdenamento = [...arrayOrdenamento];
        cloneArrayOrdenamento[idx] = cloneArray
        arrayConfigCol.splice(idx, 1, cloneArrayConfig)
        setArrayOrdenamento(cloneArrayOrdenamento)
        setArrayConfigCol(arrayConfigCol)
    }

    const agrupamento = (arr, fields, colunas, position) => {
        //[{coluna:'municipio_codigo_ibge', descricao:'municipio_naturalidade'}]
        let field = fields[0]               // um agrupamento por vez
        if (!field) {
            return arr
        }
        let retArr = Object.values(
            arr.reduce((obj, current) => {
                if (!obj[current[field.coluna]]) obj[current[field.coluna]] = { agrupado: true, descricao: current[field.descricao], dados: [] }
                obj[current[field.coluna]].dados.push(current)
                return obj
            }, {}))

        // recursivo se ainda houver agrupamentos
        if (fields.length) {
            retArr.forEach(obj => {
                obj.count = obj.dados.length
                obj.position = position || 0
                obj.dados = agrupamento(obj.dados, fields.slice(1), colunas, (position || 0) + 1)
            })
        }
        return retArr
    }

    const retornoAgrupamento = (array, agrupamentos, colunas, carregandoDados) => {
        if (!array) return;
        const retorno = agrupamento(array, agrupamentos, colunas);
        return retorno;
    }

    const htmlAgrupadoVirtuoso = useCallback((arrayAgrupado, colunas, arrayHtml, itensUsados, itemColuna, idxColuna, idxAgrupamento) => {
        let novoArrayHtml = arrayHtml ? arrayHtml : []

        if (arrayAgrupado && arrayAgrupado.length) {
            if (arrayAgrupado[0].agrupado) {
                arrayAgrupado.map((item, idx) => {
                    if (item.agrupado) {
                        if (props.repetirColunas || (!props.repetirColunas && idx === 0)) {
                            /* COLUNAS */
                            arrayHtml.push(idx === 0 ? <></> : <tr style={{ height: 10 }}></tr>)
                            arrayHtml.push(<><thead style={{ 'display': 'table-row-group' }} className={idx === 0 ? '' : 'mt-2'}>
                                <tr className="group-title">
                                    {itemColuna.colunas && itemColuna.colunas.map((y, idy) => {
                                        let classes = (y.ordenamento ? 'ordenavel ' : '') + (arrayConfigCol[idxColuna][idy] && arrayConfigCol[idxColuna][idy].ordenamento.ativo && arrayConfigCol[idxColuna][idy].coluna === y.name ? 'ordenamento_' + arrayConfigCol[idxColuna][idy].ordenamento.modo : '');
                                        return (arrayConfigCol[idxColuna][idy] && arrayConfigCol[idxColuna][idy].visivel) && <td onClick={(event) => {
                                            if (y.ordenamento) {
                                                setOrdenamento(idy, itemColuna.data, idxColuna)
                                            }
                                        }} style={{ width: y.width || 'auto' }} className={`bold coluna ${y.alinhamento && (y.alinhamento || '')} ${classes}`}>{y.label}</td>
                                    })}
                                </tr>
                            </thead>

                                <tr> <td colspan={itemColuna.colunas ? itemColuna.colunas.length : 0}></td> </tr></>)
                        }

                        item.descricao && arrayHtml.push(<tr className={item.position === 1 ? 'titulo-2' : 'titulo-1 color-gray'}><td colspan={`${colunas ? colunas.length : 0}`} dangerouslySetInnerHTML={{
                            __html: item.descricao,
                        }}
                            className={'agrupamento bold mb-1 mt-3 ' + (item.position === 1 ? ' pt-1 pl-2 mt-1 pb-1' : ' pt-2 pb-2')}></td></tr>)
                    }
                    return <tbody>{htmlAgrupadoVirtuoso(item.dados, colunas, arrayHtml, itensUsados, itemColuna, idxColuna, idxAgrupamento)}</tbody>
                })
            } else {
                const array = props.ordenarGeral ? arrayAgrupado : obterDataOrdenado(0, arrayAgrupado);
                arrayHtml.push(arrayOrdenamento ? array.map((linha, idxLinha, self) => {
                    return <tr className={'tr-data'}>
                        {colunas && colunas.map((coluna, idy) => {
                            if (coluna.name === '#' && coluna.idxAgrupamento)
                                return (arrayConfigCol[0][idy] && arrayConfigCol[0][idy].visivel) && <td className={`td-data  ${coluna.alinhamento && coluna.alinhamento || ''} ${coluna.classe && coluna.classe || ''}`}>{idxAgrupamento + 1}</td>

                            else if (coluna.name === '#' && !coluna.idxAgrupamento)
                                return (arrayConfigCol[0][idy] && arrayConfigCol[0][idy].visivel) && <td className={`td-data text-center ${coluna.classe && coluna.classe || ''}`}>{idxLinha + 1}</td>

                            return (arrayConfigCol[0][idy] && arrayConfigCol[0][idy].visivel) ? <td className={`td-data ${coluna.alinhamento && coluna.alinhamento || ''} ${coluna.classe && coluna.classe || ''}`}>{
                                linha[coluna.name]
                            }</td> : <></>
                        })}
                    </tr>
                }
                ) : <></>)

                arrayHtml.push(props.dados[0].aposAgrupamento ? props.dados[0].aposAgrupamento(arrayAgrupado, itemColuna, arrayConfigCol) : <></>)
            }
        }

        return novoArrayHtml
    }, [arrayConfigCol])

    const htmlAgrupado = useCallback((arrayAgrupado, colunas, arrayHtml, itensUsados, itemColuna, idxColuna, idxAgrupamento) => {

        if (arrayAgrupado && arrayAgrupado.length) {
            if (arrayAgrupado[0].agrupado) {
                arrayAgrupado.map((item, idx) => {
                    if (item.agrupado) {
                        if (props.repetirColunas || (!props.repetirColunas && idx === 0)) {
                            /* COLUNAS */
                            arrayHtml.push(idx === 0 ? <></> : <tr style={{ height: 10 }}></tr>)
                            arrayHtml.push(<><thead style={{ 'display': 'table-row-group' }} className={idx === 0 ? '' : 'mt-2'}>
                                <tr className="group-title">
                                    {itemColuna.colunas && itemColuna.colunas.map((y, idy) => {
                                        let classes = (y.ordenamento ? 'ordenavel ' : '') + (arrayConfigCol[idxColuna][idy] && arrayConfigCol[idxColuna][idy].ordenamento.ativo && arrayConfigCol[idxColuna][idy].coluna === y.name ? 'ordenamento_' + arrayConfigCol[idxColuna][idy].ordenamento.modo : '');
                                        return (arrayConfigCol[idxColuna][idy] && arrayConfigCol[idxColuna][idy].visivel) && <td onClick={(event) => {
                                            if (y.ordenamento) {
                                                setOrdenamento(idy, itemColuna.data, idxColuna)
                                            }
                                        }} style={{ width: y.width || 'auto' }} className={`bold coluna ${y.alinhamento && (y.alinhamento || '')} ${classes}`}>{y.label}</td>
                                    })}
                                </tr>
                            </thead>
                                <tr> <td colspan={itemColuna.colunas ? itemColuna.colunas.length : 0}></td> </tr></>)
                        }

                        item.descricao && arrayHtml.push(<tr className={item.position === 1 ? 'titulo-2' : 'titulo-1 color-gray'}><td colspan={`${colunas ? colunas.length : 0}`} dangerouslySetInnerHTML={{
                            __html: item.descricao,
                        }}
                            className={'agrupamento bold mb-1 mt-3 ' + (item.position === 1 ? ' pt-1 pl-2 mt-1 pb-1' : ' pt-2 pb-2')}></td></tr>)
                    }
                    return htmlAgrupado(item.dados, colunas, arrayHtml, itensUsados, itemColuna, idxColuna, idx)
                })
            } else {
                const array = props.ordenarGeral ? arrayAgrupado : obterDataOrdenado(0, arrayAgrupado);
                arrayHtml.push(arrayOrdenamento ? array.map((linha, idxLinha, self) => {
                    return <tr className={'tr-data'}>
                        {colunas && colunas.map((coluna, idy) => {
                            if (coluna.name === '#' && coluna.idxAgrupamento)
                                return (arrayConfigCol[0][idy] && arrayConfigCol[0][idy].visivel) && <td className={`td-data  ${coluna.alinhamento && coluna.alinhamento || ''} ${coluna.classe && coluna.classe || ''}`}>{idxAgrupamento + 1}</td>

                            else if (coluna.name === '#' && !coluna.idxAgrupamento)
                                return (arrayConfigCol[0][idy] && arrayConfigCol[0][idy].visivel) && <td className={`td-data text-center ${coluna.classe && coluna.classe || ''}`}>{idxLinha + 1}</td>

                            return (arrayConfigCol[0][idy] && arrayConfigCol[0][idy].visivel) ? <td className={`td-data ${coluna.alinhamento && coluna.alinhamento || ''} ${coluna.classe && coluna.classe || ''}`}>{
                                linha[coluna.name]
                            }</td> : <></>
                        })}
                    </tr>
                }
                ) : <></>)

                arrayHtml.push(props.dados[0].aposAgrupamento ? props.dados[0].aposAgrupamento(arrayAgrupado, itemColuna, arrayConfigCol) : <></>)
            }
        }
    }, [arrayConfigCol])

    const tipoRelatorioNormal = _ => <>
        {
            props?.dados[0] && props?.dados[0].data && props?.dados[0].data.length > 0 ?

                props.agrupamento ?

                    props.dados.map((x, idx) => {
                        let carregandoDados = false;
                        //passando duas vezes no map ^^
                        let htmlAgrupamento = [];
                        let jaUsados = [];
                        let dadosOrdenados = arrayOrdenamento && props.ordenarGeral ? obterDataOrdenado(0, x.data) : x.data
                        let dadosAgrupados = retornoAgrupamento(dadosOrdenados, props.agrupamento, x.colunas, carregandoDados);

                        htmlAgrupado(dadosAgrupados, x.colunas, htmlAgrupamento, jaUsados, x, idx)

                        return <>
                            <table>
                                {/* Remover Ordenamentos */}
                                {(arrayOrdenamento[idx] && arrayOrdenamento[idx].filter(item => item === true).length > 0) && <tr><td colspan={`${x.colunas ? x.colunas.filter(y => y.visivel).length : 0}`}
                                    className={'bold'}>
                                    <BrowserRouter>
                                        <Link to='#' className='d-none-print' onClick={() => limparOrdenamentos(idx)}>Remover ordenamentos</Link>
                                    </BrowserRouter>
                                </td></tr>}
                            </table>
                            <table className="table-agrupamento">
                                {htmlAgrupamento}
                                {/* TOTAIS GERAL */}
                                {
                                    props.dados.find((x, idx) => x.colunas && x.colunas.find((y, idy) => y.totalizar && arrayConfigCol[idx][idy] && arrayConfigCol[idx][idy].visivel)) &&
                                    <>
                                        <tr style={{ borderBottom: '1px #000 dashed' }}><td colspan={`${props.dados[0].colunas ? props.dados[0].colunas.filter(x => x.visivel).length : 0}`} className={'bold'}>Total Geral</td></tr>
                                        <tr>
                                            {
                                                props.dados[0].colunas.filter((z, idz) => arrayConfigCol[idx][idz]?.visivel).map((z, index) => {

                                                    if (z.totalizar) {
                                                        let total = 0
                                                        props.dados.filter(array => array && array.data).map(array => array.data.map(y => {
                                                            total += y[z.name] ? parseFloat(y[z.name].replace('R$ ', '').replace(/\./g, '').replace(',', '.')) : 0
                                                        }))
                                                        return (<td className={`bold td-data ${z.alinhamento && z.alinhamento || ''}`}>{formatarMoeda(total)}</td>)
                                                    }
                                                    else
                                                        return (<td className={''}></td>)
                                                })
                                            }
                                        </tr>

                                    </>


                                }
                            </table></>

                    })
                    : props.dados.map((x, idx) => {
                        return (
                            <table>
                                {/* AGRUPAMENTO */}
                                {x.agrupamento && <tr><td colspan={`${x.colunas ? x.colunas.filter(y => y.visivel).length : 0}`}
                                    className={'agrupamento bold'}>{x.agrupamento}</td></tr>}

                                {/* Remover Ordenamentos */}
                                {(x.data && x.data.length > 0) && (arrayOrdenamento[idx] && arrayOrdenamento[idx].filter(item => item === true).length > 0) && <tr><td colspan={`${x.colunas ? x.colunas.filter(y => y.visivel).length : 0}`}
                                    className={'bold'}>
                                    <BrowserRouter>
                                        <Link to='#' className='d-none-print' onClick={() => limparOrdenamentos(idx)}>Remover ordenamentos</Link>
                                    </BrowserRouter>
                                </td></tr>}

                                {/* COLUNAS */}
                                {x.data && x.data.length > 0 ? <thead style={{ 'display': 'table-row-group' }}>
                                    <tr className="group-title">
                                        {/* {x.numerarLinha && <td onClick={(event) => {}} className={`bold coluna text-center`}>#</td>} */}

                                        {x.colunas && x.colunas.map((y, idy) => {
                                            let classes = (y.ordenamento ? 'ordenavel ' : '') + (arrayConfigCol[idx][idy] && arrayConfigCol[idx][idy].ordenamento.ativo && arrayConfigCol[idx][idy].coluna === y.name ? 'ordenamento_' + arrayConfigCol[idx][idy].ordenamento.modo : '');
                                            return (arrayConfigCol[idx][idy] && arrayConfigCol[idx][idy].visivel) && <td onClick={(event) => {
                                                if (y.ordenamento)
                                                    setOrdenamento(idy, x.data, idx)

                                            }} style={{ width: y.width || 'auto' }} className={`bold coluna ${y.alinhamento && y.alinhamento || ''} ${classes}`}>{y.label}</td>
                                        })}
                                    </tr>
                                </thead> : <></>}

                                {/* DADOS */}
                                {(arrayOrdenamento ? obterDataOrdenado(idx, x.data).map((linha, idxLinha) =>
                                    <tr className={`tr-data 1 ${x.classeTr || ''}`}>
                                        {x.colunas && x.colunas.map((coluna, idy) => {

                                            if (coluna.name === '#') {
                                                return (arrayConfigCol[idx][idy] && arrayConfigCol[idx][idy].visivel) && <td className={`td-data text-center`}>{idxLinha + 1}</td>
                                            }

                                            return (arrayConfigCol[idx][idy] && arrayConfigCol[idx][idy].visivel) ? <td className={`td-data ${coluna.alinhamento && coluna.alinhamento || ''}`}>{
                                                linha[coluna.name]
                                            }</td> : <></>
                                        })}
                                    </tr>
                                ) : <></>)}

                                {/* TOTAIS AGRUPAMENTO */}

                                {
                                    props.dados.find((x, idx) => x.colunas && x.colunas.find((y, idy) => y.totalizar && arrayConfigCol[idx][idy] && arrayConfigCol[idx][idy].visivel)) &&
                                    <>
                                        <tr style={{ borderBottom: '1px #000 dashed' }}><td colspan={`${props.dados[0].colunas ? props.dados[0].colunas.filter(x => x.visivel).length : 0}`} className={'bold'}>Total Geral</td></tr>
                                        <tr>
                                            {
                                                props.dados[0].colunas.filter((z, idz) => arrayConfigCol[idx][idz]?.visivel).map((z, index) => {

                                                    if (z.totalizar) {
                                                        let total = 0
                                                        props.dados.filter(array => array && array.data).map(array => array.data.map(y => {
                                                            total += y[z.name] ? parseFloat(y[z.name].replace('R$ ', '').replace(/\./g, '').replace(',', '.')) : 0
                                                        }))
                                                        return (<td className={`bold td-data ${z.alinhamento && z.alinhamento || ''}`}>{formatarMoeda(total)}</td>)
                                                    }
                                                    else
                                                        return (<td className={''}></td>)
                                                })
                                            }
                                        </tr>

                                    </>


                                }

                                {/* {x.colunas && x.colunas.filter((z, idz) => z.totalizar && arrayConfigCol[idx][idz] && arrayConfigCol[idx][idz].visivel).length &&
                                    <tr className={'tr-data'}>
                                        {
                                            x.colunas.map((z, index) => {
                                                if (z.totalizar) {
                                                    let total = 0.0

                                                    x.data.map(linha => {

                                                        if (linha[z.name].includes('R$')) {
                                                            total += parseFloat(linha[z.name].replace('R$ ', '').replace(/\./g, '').replace(',', '.'))
                                                        } else {

                                                            total += parseFloat(linha[z.name])
                                                        }
                                                    })
                                                    if (x.data?.length > 0) {

                                                        return (<td className={`td-total td-data ${z.alinhamento && z.alinhamento || ''}`}><b>{formatarMoeda(total)}</b></td>)
                                                    }
                                                }
                                                else
                                                    return (<td className={`td-total td-data`}></td>)
                                            })
                                        }
                                    </tr> || <></>
                                } */}
                            </table>
                        )
                    }) :
                <MensagemPadraoRelatorio />

        }

        {
            props.dados[0] && props.dados[0]?.data?.length > 0 && props.dados[0]?.aposRelatorio ? props.dados[0].aposRelatorio(props.dados, arrayConfigCol) : <></>
        }
        <tr><td colspan={`${props.dados[0].colunas ? props.dados[0].colunas.filter(x => x.visivel).length : 0}`} className={'last'}>&nbsp;</td></tr>
    </>

    const tipoRelatorioVirtuoso = _ => <div class="div-virtuoso d-none-print">
        <div className='delete-starts' />

        {props?.dados[0] && props?.dados[0].data && props?.dados[0].data.length > 0 ?

            props.agrupamento ?

                props.dados.map((x, idx) => {
                    let carregandoDados = false;
                    let jaUsados = [];
                    let dadosOrdenados = arrayOrdenamento && props.ordenarGeral ? obterDataOrdenado(0, x.data) : x.data
                    let dadosAgrupados = retornoAgrupamento(dadosOrdenados, props.agrupamento, x.colunas, carregandoDados);

                    return <>
                        <table>
                            {/* Remover Ordenamentos */}
                            {(arrayOrdenamento[idx] && arrayOrdenamento[idx].filter(item => item === true).length > 0) && <tr><td colspan={`${x.colunas ? x.colunas.filter(y => y.visivel).length : 0}`}
                                className={'bold'}>
                                <BrowserRouter>
                                    <Link to='#' className='d-none-print' onClick={() => limparOrdenamentos(idx)}>Remover ordenamentos</Link>
                                </BrowserRouter>
                            </td></tr>}
                        </table>
                        <table className="table-agrupamento">
                            {
                                <TableVirtuoso
                                    style={{ height: 1000 }}
                                    useWindowScroll
                                    data={dadosAgrupados}
                                    itemContent={(index, item) => {
                                        return <table key={index}>{htmlAgrupadoVirtuoso([item], x.colunas, [], jaUsados, x, idx, index)}</table>
                                    }

                                    } />
                            }
                            {/* {htmlAgrupamento} */}
                            {/* TOTAIS GERAL */}
                            {
                                props.dados.find((x, idx) => x.colunas && x.colunas.find((y, idy) => y.totalizar && arrayConfigCol[idx][idy] && arrayConfigCol[idx][idy].visivel)) &&
                                <>
                                    <tr style={{ borderBottom: '1px #000 dashed' }}><td colspan={`${props.dados[0].colunas ? props.dados[0].colunas.filter(x => x.visivel).length : 0}`} className={'bold'}>Total Geral</td></tr>
                                    <tr>
                                        {
                                            props.dados[0].colunas.filter((z, idz) => arrayConfigCol[idx][idz]?.visivel).map((z, index) => {

                                                if (z.totalizar) {
                                                    let total = 0
                                                    props.dados.filter(array => array && array.data).map(array => array.data.map(y => {
                                                        total += y[z.name] ? parseFloat(y[z.name].replace('R$ ', '').replace(/\./g, '').replace(',', '.')) : 0
                                                    }))
                                                    return (<td className={`bold td-data ${z.alinhamento && z.alinhamento || ''}`}>{formatarMoeda(total)}</td>)
                                                }
                                                else
                                                    return (<td className={''}></td>)
                                            })
                                        }
                                    </tr>
                                </>

                            }
                        </table></>
                })
                : props.dados.map((x, idx) => {
                    let dadosOrdenados = arrayOrdenamento ? obterDataOrdenado(0, x.data) : x.data
                    return (
                        <table>
                            {/* AGRUPAMENTO */}
                            {x.agrupamento && <tr><td colspan={`${x.colunas ? x.colunas.filter(y => y.visivel).length : 0}`}
                                className={'agrupamento bold'}>{x.agrupamento}</td></tr>}

                            {/* Remover Ordenamentos */}
                            {(arrayOrdenamento[idx] && arrayOrdenamento[idx].filter(item => item === true).length > 0) && <tr><td colspan={`${x.colunas ? x.colunas.filter(y => y.visivel).length : 0}`}
                                className={'bold'}>
                                <BrowserRouter>
                                    <Link to='#' className='d-none-print' onClick={() => limparOrdenamentos(idx)}>Remover ordenamentos</Link>
                                </BrowserRouter>
                            </td></tr>}

                            {/* DADOS */}
                            {(arrayOrdenamento ?
                                <>
                                    <TableVirtuoso
                                        style={{ height: 1000 }}
                                        useWindowScroll
                                        data={dadosOrdenados}
                                        fixedHeaderContent={() => (
                                            <tr className="group-title">
                                                {/* {x.numerarLinha && <td onClick={(event) => {}} className={`bold coluna text-center`}>#</td>} */}

                                                {x.colunas && x.colunas.map((y, idy) => {
                                                    let classes = (y.ordenamento ? 'ordenavel ' : '') + (arrayConfigCol[idx][idy] && arrayConfigCol[idx][idy].ordenamento.ativo && arrayConfigCol[idx][idy].coluna === y.name ? 'ordenamento_' + arrayConfigCol[idx][idy].ordenamento.modo : '');
                                                    return (arrayConfigCol[idx][idy] && arrayConfigCol[idx][idy].visivel) && <th onClick={(event) => {
                                                        if (y.ordenamento)
                                                            setOrdenamento(idy, x.data, idx)

                                                    }} style={{ width: y.width || 'auto' }} className={`bold coluna ${y.alinhamento && y.alinhamento || ''} ${classes}`}>{y.label}</th>
                                                })}
                                            </tr>
                                        )}
                                        fixedFooterContent={() => {
                                            var configCol = arrayConfigCol[idx];
                                            {/* TOTAIS AGRUPAMENTO */ }
                                            return (x.colunas && x.colunas.filter((z, idz) => z.totalizar && configCol[idz] && configCol[idz].visivel).length &&
                                                <tr className={'tr-data'}>
                                                    {
                                                        x.colunas.map((z, index) => {
                                                            if (configCol[index] && !configCol[index].visivel)
                                                                return;

                                                            if (z.totalizar) {
                                                                let total = 0.0
                                                                x.data.map(linha => {
                                                                    var n = linha[z.name]
                                                                    total += n ? parseFloat(n.replace('R$ ', '').replace(/\./g, '').replace(',', '.')) : 0
                                                                })
                                                                return (<td className={`bold td-total td-data ${z.alinhamento && z.alinhamento || ''}`}>{formatarMoeda(total)}</td>)
                                                            }
                                                            else
                                                                return (<td className={`td-total td-data`}></td>)
                                                        })
                                                    }
                                                </tr> || <></>)
                                        }}
                                        itemContent={(idxLinha, linha) => {
                                            return <>
                                                {x.colunas && x.colunas.map((coluna, idy) => {

                                                    if (coluna.name === '#') {
                                                        return (arrayConfigCol[idx][idy] && arrayConfigCol[idx][idy].visivel) && <td className={`td-data text-center`}>{idxLinha + 1}</td>
                                                    }

                                                    return (arrayConfigCol[idx][idy] && arrayConfigCol[idx][idy].visivel) ? <td className={`td-data ${coluna.alinhamento && coluna.alinhamento || ''}`}>{
                                                        linha[coluna.name]
                                                    }</td> : <></>
                                                })}
                                            </>
                                        }
                                        } />
                                </> : <></>)}



                        </table>
                    )
                })
            :
            <MensagemPadraoRelatorio />
        }


        {
            props.dados[0] && props.dados[0]?.data?.length > 0 && props.dados[0].aposRelatorio ? props.dados[0].aposRelatorio(props.dados, arrayConfigCol) : <></>
        }
        <tr><td colspan={`${props.dados[0].colunas ? props.dados[0].colunas.filter(x => x.visivel).length : 0}`} className={'last'}>&nbsp;</td></tr>
        <div className='delete-ends' />
    </div>

    const tipoRelatorioDefault = _ => {
        return props.virtuoso ? tipoRelatorioVirtuoso() : tipoRelatorioNormal()
    }

    const htmlHeader = <>
        <div className='d-block-print'>
            <div>
                <div className={'col-12 row'}>
                    <div className="col-4 pl-0">
                        <h5>{props.titulo}</h5>
                        {
                            props.tituloData ?
                                <h6>{moment(props.tituloData, 'DD/MM/YYYY').format('DD') + ' de ' + (moment(props.tituloData, 'DD/MM/YYYY').format('MM') || meses.default) + ' de ' + moment(props.tituloData, 'DD/MM/YYYY').format('YYYY')}</h6>
                                : <h6>{actualDate.getDate().toString().padStart(2, '0') + ' de ' + (meses[actualDate.getMonth() + 1] || meses.default) + ' de ' + actualDate.getFullYear()}</h6>
                        }
                    </div>
                    <div className="col-8 text-right">
                        <img alt='logo' className="logo-menu" src={require('../assets/img/logo-menu.png')}></img>
                    </div>
                </div>
            </div>
            <div className="dados-relatorio pl-0 pr-0" ref={relRef}>
                <div className="group-filter mb-2">
                    <h6 className={'bold'}><span className={'bold icon-Filter-2'}></span> Filtros Utilizados {props.filtros && props.filtros.length && props.removerFiltros ? <span class="span-button d-none-print" onClick={() => props.removerFiltros()}>Remover Filtros</span> : <></>}
                    </h6>
                    {props.filtros && props.filtros.length &&
                        props.filtros.map(x => {
                            return <><span><b>{x.filtro}: </b>{x.dado}</span><br /></>
                        }
                        ) ||
                        <span className={'filter'}>Nenhum filtro selecionado</span>
                    }
                </div>
            </div>
        </div>
    </>

    const switchTipoRelatorio = dados => {
        if (!props.dados[0].relatorioEspecifico)
            return tipoRelatorioDefault()
        else
            return callRelatorioEspecifico(false)
    }

    const callRelatorioEspecifico = (impressao) => {
        if (!impressao) {
            return props.dados[0].relatorioEspecifico(props.dados, arrayConfigCol, { setOrdenamento, arrayOrdenamento, limparOrdenamentos, obterDataOrdenado }, htmlHeader)
        } else {
            return props.dados[0].relatorioEspecificoImpressao(props.dados, arrayConfigCol, { setOrdenamento, arrayOrdenamento, limparOrdenamentos, obterDataOrdenado }, htmlHeader)
        }
    }

    useEffect(() => {
        if (loadingPrint) {
            setTimeout(() => {
                imprimir();
            }, 10)
        }
    }, [loadingPrint])

    const mostrarLoading = useCallback(async () => {
        setLoadingPrint(true);
    }, [])

    const finalizarImprimir = useCallback(async (toastId) => {
        toast.update(toastId, { autoClose: 2000 });
        setLoadingPrint(false);
    }, [])

    const imprimir = () => {
        var toastPrint = toastPrintMessage('Estamos preparando sua impressão, aguarde alguns segundos até que ' + (isMobile ? 'seu PDF seja gerado e seu download seja concluído.' : 'seja concluído.'))
        try {
            var html = document.getElementsByTagName('html')[0].innerHTML;
            html = html.replace(document.getElementsByTagName('body')[0].innerHTML, "<body>" + reference.current.innerHTML + "</body>");


            if (props.virtuoso) {
                if (props.dados[0].relatorioEspecifico) {
                    html = removeContentBetweenDivs(html, 'delete-starts', 'delete-ends')
                    const htmlString = ReactDOMServer.renderToString(callRelatorioEspecifico(true));
                    html = html.replace(`<div class="group-data mb-1">`, `<div class="group-data mb-1">` + htmlString)
                } else {
                    const htmlString = ReactDOMServer.renderToString(tipoRelatorioNormal());
                    html = removeContentBetweenDivs(html, 'delete-starts', 'delete-ends')
                    html = html.replace(`<div class="group-data mb-1">`, `<div class="group-data mb-1">` + htmlString)
                }
            }

            if (props?.orientacaoPadrao) {
                if (props.dados[0].relatorioEspecifico) {
                    html = removeContentBetweenDivs(html, 'delete-starts', 'delete-ends')
                    const htmlString = ReactDOMServer.renderToString(callRelatorioEspecifico(true));
                    html = html.replace(`<div class="group-data mb-1">`, `<div class="group-data mb-1 landscape-report">` + htmlString)
                }
            }

            html = html.replace("rgb(248, 248, 248) !important;", "white !important;");

            if (props.ajustaEscala)
                html = html.replace("<head>", `
                <head>
                <style>
                    @media print {
                        body {
                            width: 100%;
                            max-width: 100%;
                            margin: 0;
                            font-size: 8px !important;
                        }
                        @page {
                            margin: 1cm; /* Ajustar margem da página para 1cm */
                        }
                        table {
                            width: 100%;
                            table-layout: auto;
                        }
                        th, td {
                            font-size: 8px !important ;
                        }
                      
                    }
                    .group-data {
                        overflow: visible !important;
                    }
                </style>
            `);

            const size = stringSizeMB(html)
            if (size && size > 25) {
                //o html excedeu 25mb 
                throw Error("Não foi possível gera a impressão! Relatório com conteúdo extenso");
            }

            var bodyFormData = new FormData();

            // Comprimir para enviar
            const htmlComprimido = comprimirString(html);

            bodyFormData.append('html', htmlComprimido);
            bodyFormData.append('urlBase', process.env.REACT_APP_URL_BASE_PRINT);
            bodyFormData.append('pageOrientation', props?.orientacaoPadrao ? props?.orientacaoPadrao : 'portrait');

            axios.post('https://htmltopdf.selectasistemas.com/convert/htmltopdfcompressed', bodyFormData, { "Content-Type": "multipart/form-data" })
                .then(response => {
                    let basePdf = response.data;
                    let byteCharacters = atob(basePdf);
                    let byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    let byteArray = new Uint8Array(byteNumbers);
                    let file = new Blob([byteArray], { type: 'application/pdf;base64' });
                    let fileURL = URL.createObjectURL(file);
                    if (!isMobile) {
                        window.open(fileURL);
                    }
                    else {
                        const link = document.createElement('a');
                        link.href = fileURL;
                        link.setAttribute(
                            'download',
                            `relatorio.pdf`,
                        );

                        // Append to html link element page
                        document.body.appendChild(link);

                        // Start download
                        link.click();

                        // Clean up and remove the link
                        link.parentNode.removeChild(link);
                    }
                    finalizarImprimir(toastPrint);
                }).catch(error => {
                    finalizarImprimir(toastPrint);
                    mostrarToast('erro', 'Não foi possível gerar sua impressão.')
                })
        } catch (error) {
            finalizarImprimir(toastPrint);
            mostrarToast('erro', error.message || 'Não foi possível gerar sua impressão.')
        }
    }

    const mostrarModalColunas = useCallback(() => {
        setShowModalColunas(true)
    }, [])

    const mostrarModalModalFiltros = useCallback(() => {
        props.setShowModalFiltros(true)
    }, [])

    const TipoRelatorio = () => {
        const renderizacaoCondicional = useMemo(() => {
            if (props.dados.length && arrayConfigCol.length) {
                return switchTipoRelatorio(props.dados);
            } else {
                return <span>Nenhum dado encontrado!</span>;
            }
        }, [arrayConfigCol]);

        return renderizacaoCondicional;
    };

    return (
        <>

            {/* <ReactToPrint pageStyle={`@page { size: a4 ${props.orientacao || 'auto'} !important; margin-bottom: 10px !important; counter-increment: page;}`} trigger={() => <button className={"hidden d-none"} ref={btnPrint} />} content={() => reference.current} /> */}
            <div className={`impressao card mt-3 ${props.orientacao || 'portrait'}`} ref={reference} >
                <div className="card-body" >
                    <div className={'d-block-print'}>
                        <div className={'col-12 row'}>
                            <div className="col-4">
                                <h5>{props.titulo}</h5>
                                {
                                    props.tituloData ?
                                        <h6>{moment(props.tituloData, 'DD/MM/YYYY').format('DD') + ' de ' + (moment(props.tituloData, 'DD/MM/YYYY').format('MM') || meses.default) + ' de ' + moment(props.tituloData, 'DD/MM/YYYY').format('YYYY')}</h6>
                                        : <h6>{actualDate.getDate().toString().padStart(2, '0') + ' de ' + (meses[actualDate.getMonth() + 1] || meses.default) + ' de ' + actualDate.getFullYear()}</h6>
                                }
                            </div>
                            <div className="col-8 text-right">
                                <img alt='logo' className="logo-menu" src={require('../assets/img/logo-menu.png')}></img>
                            </div>
                        </div>
                    </div>
                    <div className={'d-none-print ajusta-titulo'}>
                        <div className={'col-12 row mr-0 ml-0 pl-0 pr-0'}>
                            <div className="col-12 row-space-between p-0">
                                <div>
                                    <BtnAcao icone="icon-Arrow-Left" texto="Voltar" action={() => props.history ?
                                        (props.history.location && props.history.location.listagem && props.history.location.listagem.goBackAlternativo ? window.history.go(-2) :
                                            (document.referrer.length > 0 ? (!document.referrer.includes(props.history.location.pathname) ? props.history.goBack() : props.history.push('/dashboard')) :
                                                props.history.push('/dashboard'))) :
                                        (document.referrer.length > 0 && document.referrer !== window.location.href ? window.history.back() : window.location.href = '/dashboard')} />
                                </div>
                                <div className="text-right">
                                    {props.filtros ? <BtnAcao icone="icon-Filter-2" texto="Filtros" action={mostrarModalModalFiltros} /> : <></>}
                                    <BtnAcao icone="icon-Pen" texto="Editar Colunas" action={mostrarModalColunas} />
                                    <CsvBuilder dados={props.dados} />
                                    <Imprimir loading={loadingPrint} action={mostrarLoading} />
                                </div>
                            </div>
                            <div className="col-12 titulo-visualizacao">
                                <h5>{props.titulo}</h5>
                                {
                                    props?.dados[0]?.retiraDataHeader ? <></> :
                                        props.tituloData ?
                                            <h6>{moment(props.tituloData, 'DD/MM/YYYY').format('DD') + ' de ' + meses[parseInt(moment(props.tituloData, 'DD/MM/YYYY').format('MM'))] + ' de ' + moment(props.tituloData, 'DD/MM/YYYY').format('YYYY')}</h6>
                                            : <h6>{actualDate.getDate().toString().padStart(2, '0') + ' de ' + (meses[actualDate.getMonth() + 1] || meses.default) + ' de ' + actualDate.getFullYear()}</h6>

                                }
                            </div>
                        </div>
                    </div>


                    <div className={"dados-relatorio " + (props.classeRelatorio || "")} ref={relRef}>
                        <div className="group-filter">
                            <h6 className={'bold'}><span className={'bold icon-Filter-2'}></span> Filtros Utilizados {props.filtros && props.filtros.length && props.removerFiltros ? <span class="span-button d-none-print" onClick={() => props.removerFiltros()}>Remover Filtros</span> : <></>}
                            </h6>
                            {props.filtros && props.filtros.length &&
                                props.filtros.map(x => {
                                    return <><span><b>{x.filtro}: </b>{x.dado}</span><br /></>
                                }
                                ) ||
                                <span className={'filter'}>Nenhum filtro selecionado</span>
                            }
                        </div>
                        {props.loading && <LoadRelatorio refLoading={relRef} />}
                        {!props.loading && props.dados && <div className={"group-data mb-1"}>
                            <div className='delete-starts' />
                            <TipoRelatorio />
                            <div className='delete-ends' />
                            {props.corpoRelatorio && props.corpoRelatorio}
                        </div>}
                    </div>
                </div>
                <div className="footer-only-print">
                    <div className="col-md-12">
                        <span><i>Relatório emitido por <b>{user.nome}</b> no dia {moment().format('DD/MM/YYYY')} às {moment().format('HH:mm:ss')}</i></span>
                    </div>
                </div>
            </div>
            <ModalCadastro setShowModal={props.setShowModalFiltros} show={props.showModalFiltros} titulo={'Filtros'}>
                {props.children}
            </ModalCadastro>

            <ModalCadastro setShowModal={setShowModalColunas} show={showModalColunas} titulo={'Colunas'}>
                {props.dados && props.dados.length && arrayConfigCol.length ?
                    obterChecksMudarVisibilidade(props)
                    :
                    <></>}
            </ModalCadastro>
        </>
    )
})

export { ConsultaRelatorio }